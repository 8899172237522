import { Col } from "react-bootstrap";
import { Link } from 'react-router-dom';

export const ProjectCard = ({ title, description, imgUrl, repoUrl, liveUrl }) => {
    const buttonStyle = {
      backgroundColor: 'black',
      color: 'rgb(79, 198, 198)',
      border: '5px',
      borderRadius: '5px',
      padding: '10px 20px',
      margin: '10px',
      display: 'inline-block',
      textDecoration: 'none',
      textAlign: 'center',
      cursor: 'pointer'
    };
  
    const containerStyle = {
      display: 'flex',
      justifyContent: 'center',
      gap: '20px', // Specific gap between the buttons
      margin: '20px 0' // Margin from top and bottom
    };
  return (
    <Col sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} alt={title} />
        <div className="proj-txtx">
          <h4>{title}</h4>
          <span>{description}</span>
        </div>
      </div>
      <div className="proj-links">
        <button style={buttonStyle}>
        <a href={repoUrl} target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>GitHub</a>
      </button>
      <button style={buttonStyle}>
        <a href={liveUrl} target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>Website</a>
      </button>
          </div>
    </Col>
  );
}
