import { Container, Row, Col, Tab } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/projImg1.png";
import projImg2 from "../assets/img/projImg2.png";
import rgtqst from "../assets/img/RightQuestimg.png";
import TrackVisibility from "react-on-screen";
import 'animate.css';
import colorSharp2 from "../assets/img/color-sharp2.png";

export const Projects = () => {
  const projects = [
    {
      title: "Job Portal",
      description: "Job Portal using MERN",
      imgUrl: projImg1,
      repoUrl: "https://github.com/rudxra/Mern-Job_Portal",
      liveUrl: "https://job-portal-87c03.firebaseapp.com/"
    },
    {
      title: "Weather App",
      description: "Realtime Weather App using ReactJS",
      imgUrl: projImg2,
      repoUrl: "https://github.com/rudxra/weatherApp",
      liveUrl: ""
    },
    {
      title: "Rights Quest",
      description: "Empowering Children's Rights",
      imgUrl: rgtqst,
      repoUrl: "https://github.com/rudxra/RightQuest",
      liveUrl: "https://rightsquest-1.web.app/"
    }
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2>Projects</h2>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <Row>
                          {projects.map((project, index) => (
                            <ProjectCard key={index} {...project} />
                          ))}
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              }
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="background"></img>
    </section>
  );
};
