import React from "react";
import Button from '@mui/material/Button';
import cloud from '../assets/img/gcpimg.png'
import styled, { keyframes, css } from "styled-components";

function Certifications() {
  const row1 = [
    "https://cdn.qwiklabs.com/MfXm3Olm4qpvd7kAaeuCCHA3bDOGBZwB5k5a%2FmAG7Ac%3D",
    "https://cdn.qwiklabs.com/bNjRV%2B1h4R5jG35gJmjgIScCK6kIR6352zd9JIVbM6I%3D",
    "https://images.credly.com/size/220x220/images/4dda8ae4-99ee-476c-bca3-6f0adbab42fe/image.png",
    "https://cdn.qwiklabs.com/DXdHjzJNLDmnq4%2BSI%2BL%2FxxBQeIodR5Yh93ywkxd2N4c%3D",
    "https://cdn.qwiklabs.com/zf4W09BpF8ntvKRBqeH3VntMFtb%2Bz8Itfc9FSCiAlL0%3D",
    "https://cdn.qwiklabs.com/ukpGfyIgO45ouhL2fRNpWVphF2VVJ9ti%2BbWQEIpdRKI%3D",
    
  ];

  const row2 = [
    "https://cdn.qwiklabs.com/Ep3VKgjzXiYW%2BvS7bWyrPt7IUqjeBat3dkphmkFwsRU%3D",
    "https://cdn.qwiklabs.com/P8gtKD1Uruqezk6G9XiuVAUW0tDqNDEj%2Bjy2Ftjqbjk%3D",
    "https://cdn.qwiklabs.com/WbUxwAElFJf9nLQsRMgACD3bcizi4Yb2EFuAWRFo1iY%3D",
    "https://cdn.qwiklabs.com/2bFIvlTQXTLbKX0mEvGLi19kjOhO2S%2BvJNvUFMluFG0%3D",
    "https://cdn.qwiklabs.com/%2Fb6vXtWRtaGoDla7LMwPf7bX6UgDdY81abF2IU9Ouyc%3D",
    "https://cdn.qwiklabs.com/E8hNK7UPo7zewuXmMtjMXSYmftHYrkhle%2FZpnaak8dI%3D",
  ];
  
  const row3 = [
    "https://cdn.qwiklabs.com/zf4W09BpF8ntvKRBqeH3VntMFtb%2Bz8Itfc9FSCiAlL0%3D",
    "https://cdn.qwiklabs.com/t0b88bg6YHVVcyfjgBBGP%2BmVfr2k8zm1qrR9os9JBLc%3D",
    "https://cdn.qwiklabs.com/r6WXBsEm%2B4pSMiqQaMvc9RRpAVoY4I172ic7O5I%2FiU0%3D",
    "https://cdn.qwiklabs.com/C29WcLiPHUd2uj1zMaF2MepWKhC%2Bvs7WTUMUTA4nMFU%3D",
    "https://cdn.qwiklabs.com/vOzGSmnitl6CgEDxkVjf%2FmyjweGiVE4n0891NLW4hZ8%3D",
    "https://cdn.qwiklabs.com/%2FX2jwzmWtD0IjbWRpVyW9CH0tjmbSpgY8oDBwdYxN5M%3D",

  ];

  return (
    <AppContainer>
      <Wrapper>
      <h2 style={{ color: 'white', fontWeight: 'bold' }}>My Licenses & Certifications</h2>
        <Marquee>
          <MarqueeGroup>
            {row1.map((el) => (
              <ImageGroup>
                <Image src={el} />
              </ImageGroup>
            ))}
          </MarqueeGroup>
          <MarqueeGroup>
            {row1.map((el) => (
              <ImageGroup>
                <Image src={el} />
              </ImageGroup>
            ))}
          </MarqueeGroup>
        </Marquee>
        <Marquee>
          <MarqueeGroup2>
            {row2.map((el) => (
              <ImageGroup>
                <Image src={el} />
              </ImageGroup>
            ))}
          </MarqueeGroup2>
          <MarqueeGroup2>
            {row2.map((el) => (
              <ImageGroup>
                <Image src={el} />
              </ImageGroup>
            ))}
          </MarqueeGroup2>
        </Marquee>
        {/* <Marquee>
          <MarqueeGroup>
            {row3.map((el) => (
              <ImageGroup>
                <Image src={el} />
              </ImageGroup>
            ))}
          </MarqueeGroup>
          <MarqueeGroup>
            {row3.map((el) => (
              <ImageGroup>
                <Image src={el} />
              </ImageGroup>
            ))}
          </MarqueeGroup>
        </Marquee> */}
        <h2 style={{ color: 'white', fontWeight: 'bold', border:'3px',margin:'5px' }}><Button href="https://www.credly.com/users/ruddra-shukla.3dc9acd9" variant="contained" style={{backgroundColor:'black'}}>View all my Licenses & Certifications</Button></h2>
      </Wrapper>
    </AppContainer>
  );
}

export default Certifications;

const AppContainer = styled.div`
  width: 100vw;
  height: 100vh;
  color: #000000;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 100%;
  height: fit-content;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

// const Text = styled.div`
//   font-size: 35px;
//   font-weight: 500;
//   margin-bottom: 10px;
//   color: #02203c;
// `;

// const Note = styled.div`
//   font-size: 18px;
//   font-weight: 200;
//   margin-bottom: 40px;
//   color: #7c8e9a;
// `;

const Marquee = styled.div`
  display: flex;
  width: 1200px;
  overflow: hidden;
  user-select: none;

  mask-image: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 10%,
    hsl(0 0% 0% / 1) 90%,
    hsl(0 0% 0% / 0)
  );
`;

const scrollX = keyframes`
  from {
    left: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

const common = css`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
  width: 100%;
  animation: ${scrollX} 30s linear infinite;
`;

const MarqueeGroup = styled.div`
  ${common}
`;
const MarqueeGroup2 = styled.div`
  ${common}
  animation-direction: reverse;
  animation-delay: -3s;
`;

const ImageGroup = styled.div`
  display: grid;
  place-items: center;
  width: clamp(10rem, 1rem + 40vmin, 30rem);
  padding: calc(clamp(10rem, 1rem + 30vmin, 30rem) / 10);
`;

const Image = styled.img`
  object-fit: contain;
  width: 130%;
  height: 130%;
  /* border: 1px solid black; */
  border-radius: 0.5rem;
  aspect-ratio: 16/9;
  padding: 5px 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;