import { Col,Container,Row } from "react-bootstrap";
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import logo from "../assets/img/logo.svg"
import navIcon from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';

export const Footer = () =>{
    return (
        <footer className="footer">
            <Container>
                <Row className="align-item-center">
                <Col sm={6}>
                    <img src={logo} alt="logo"/>
                </Col>
                <Col sm={6} className="text-center text-sm-end">
                    <div className="social-icon">
                        <a href=""><img src={navIcon}/></a>
                        <a href=""><img src={navIcon2}/></a>
                        <a href=""><img src={navIcon3}/></a>
                    </div>
                    <p>ruddra.shukla@outlook.com</p>
                    <p>CopyRight 2024. All rights reserved</p>
                </Col>             
                </Row>
            </Container>
        </footer>
    )
}