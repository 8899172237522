import React from 'react';
import Spline from '@splinetool/react-spline';
import './SplineBackground.css';

export default function SplineBackground() {
  const styles = {
    container: {
      marginTop: '8vh',
      paddingTop: '9vh',
    },
  };

  return (
    <div>
      <style>
        {`
          @media (max-width: 992px) { /* Medium devices (tablets, 768px and up) */
            .spline-background {
              margin-top: 0 !important;
              padding-top: 0 !important;
            }
          }

          @media (max-width: 576px) { /* Small devices (phones, 576px and up) */
            .spline-background {
              margin-top: 0 !important;
              padding-top: 0 !important;
            }
          }
        `}
      </style>
      <div className="spline-background" style={styles.container}>
        <Spline scene="https://prod.spline.design/TvV0UcoFhBH27xgc/scene.splinecode" />
      </div>
    </div>
  );
}
